export const useFullscreenDropzoneStore = defineStore(
  "fullscreen-dropzone",
  () => {
    const isOverDropZone = ref(false);
    const isSomeDropZoneMounted = ref(false);
    const isValid = ref(false);

    return {
      isOverDropZone,
      isSomeDropZoneMounted,
      isValid,
    };
  },
);
